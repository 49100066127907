<template>
  <div class="border-2 border-dashed border-grey-200 rounded">
    <ValidationObserver ref="imageUploadValidation">
      <ValidationProvider
        v-slot="{ validate }"
        tag="div"
        name="imageInput"
        rules="image_upload"
        :class="['relative pt-20 px-24 flex flex-col items-center justify-center', { 'pb-20': !file }]"
      >
        <input
          ref="imageInput"
          accept="image/*"
          type="file"
          class="w-full h-full absolute top-0 left-0 opacity-0 cursor-pointer"
          @change="onInputChange(validate)"
        >

        <img :src="image" alt="upload image" class="h-40 w-40">
        <div class="flex items-center mt-4 text-sm text-grey-500">
          <el-button type="action-indigo" class="mr-4" @click="onUpload">Upload a file</el-button> or drag and drop
        </div>
        <p class="mt-4 text-grey-400 text-xs">PNG, JPG up to 10MB</p>
      </ValidationProvider>
      <div v-if="file" class="es-caption pt-4 flex items-center justify-center cursor-pointer px-24 mb-4 mt-16">
        <span class="text-xs text-grey-200 mr-10">{{ file.name }}</span>
        <span class="icon-close text-grey-200 text-sm" @click.stop="onClear" />
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import ElButton from '@/components/shared/ELButton'
import { mapActions } from 'vuex'

export default {
  name: 'ElUpload',

  components: { ElButton },

  data () {
    return {
      imageSize: 0,
      file: null
    }
  },

  computed: {
    image () {
      return require('@/assets/icons/upload-image.svg')
    }
  },

  methods: {
    ...mapActions(['showNotification']),

    uploadValidate (e) {
      console.log(e)
      console.log(1)
    },

    onUpload () {
      console.log(this.$refs.imageInput)
      this.$refs.imageInput.click()
    },

    onInputChange (validate) {
      const file = this.$refs.imageInput.files[0]
      this.file = file

      validate(file ? file.size : 0)
      this.$refs.imageUploadValidation.validate(this.imageSize).then(isValid => {
        if (!isValid) {
          const errors = [...this.$refs.imageUploadValidation.errors.imageInput]
          this.showNotification({ type: 'error', message: errors })
          return false
        }
        this.$emit('file', this.file)
      })
    },

    onClear () {
      this.$refs.imageInput.value = ''
      this.file = null
      this.$emit('file', this.file)
    }
  }
}
</script>
