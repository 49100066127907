<template>
  <component
    :is="tag"
    ref="elButton"
    :disabled="disabled"
    :class="[
      'flex leading-none relative focus:outline-none',
      isDisabled,
      {
        'default': isDefault,
        'primary': isPrimary,
        'primary-dark': isPrimaryDark,
        'danger': isDanger,
        'indigo': isIndigo,
        'link': isLink,
        'action': isAction && !disabled,
        'action-indigo': isActionIndigo && !disabled,
        'action-disabled': isAction && disabled,
        'py-8': isIcon && !isAction && !isLink,
        'py-11': !isIcon && !isAction && !isActionIndigo && !isLink && !loading,
        'py-6': loading
      }
    ]"
    v-on="$listeners"
  >
    <el-loader v-if="loading" loader-class="w-22 h-22 mx-auto" />

    <template v-else>
      <div
        v-if="isIcon"
        class="flex items-center justify-center pr-8"
      >
        <slot
          name="icon"
        />
      </div>

      <div class="flex-1">
        <slot />
      </div>
    </template>
  </component>
</template>

<script>
const ElLoader = () => import('@/components/shared/ELLoader')

export default {
  name: 'ElButton',

  components: { ElLoader },

  props: {
    disabled: { type: Boolean, default: false },
    tag: { type: String, default: 'button' },
    type: { type: String, default: 'default' },
    loading: { type: Boolean, default: false }
  },

  computed: {
    isDefault () {
      return this.type === 'default'
    },

    isPrimary () {
      return this.type === 'primary' && !this.disabled && !this.loading
    },

    isPrimaryDark () {
      return this.type === 'primary-dark' && !this.disabled && !this.loading
    },

    isIndigo () {
      return this.type === 'indigo' && !this.disabled && !this.loading
    },

    isDanger () {
      return this.type === 'danger' && !this.disabled && !this.loading
    },

    isLink () {
      return this.type === 'link'
    },

    isAction () {
      return this.type === 'action'
    },

    isActionIndigo () {
      return this.type === 'action-indigo'
    },

    isIcon () {
      return !!this.$slots.icon
    },

    isDisabled () {
      return (this.disabled && !this.isLink && !this.isAction) || this.loading ? 'cursor-not-allowed bg-grey-200 rounded px-16 text-grey-300 border border-transparent' : ''
    }
  },

  watch: {
    loading (newVal) {
      if (newVal) {
        console.log()
        const elWidth = this.$refs.elButton.offsetWidth
        const elHeight = this.$refs.elButton.offsetHeight
        this.$refs.elButton.style.width = `${elWidth}px`
        this.$refs.elButton.style.height = `${elHeight}px`
      } else {
        this.$refs.elButton.style = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.default {
  @apply items-center justify-center px-16 text-sm text-grey-500 border border-grey-200 bg-white rounded outline-none
}
.default:hover {
  @apply text-grey-400
}
.default:focus {
  @apply shadow-outline-blue;
}

.primary {
  @apply items-center justify-center px-16 text-sm text-white border border-transparent bg-green outline-none rounded
}
.primary:hover {
  @apply bg-green-500
}
.primary:focus {
  @apply shadow-outline-green;
}

.primary-dark {
  @apply items-center justify-center px-16 text-sm text-white border border-transparent bg-green-400 outline-none rounded
}
.primary-dark:hover {
  @apply bg-green
}
.primary-dark:focus {
  @apply shadow-outline-green;
}

.danger {
  @apply items-center justify-center px-16 text-sm text-white border border-transparent bg-red-200 outline-none rounded;
}
.danger:hover {
  @apply bg-red-300
}
.danger:focus {
  @apply shadow-outline-green;
}

.indigo {
  @apply items-center justify-center px-16 text-sm text-white border border-transparent bg-indigo-600 outline-none rounded
}
.indigo:hover {
  @apply bg-indigo
}
.indigo:focus {
  @apply shadow-outline-indigo;
}

.link {
  @apply text-sm text-green-400 no-underline cursor-pointer
}
.link:hover {
  @apply text-green
}

.action {
  @apply text-sm text-green no-underline cursor-pointer
}
.action-indigo {
  @apply text-sm text-indigo-600 no-underline cursor-pointer
}
.action-indigo:hover {
  @apply text-indigo
}
.action-disabled {
  @apply text-sm no-underline cursor-not-allowed text-grey-200
}
</style>
