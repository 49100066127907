var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,_vm._g({ref:"elButton",tag:"component",class:[
    'flex leading-none relative focus:outline-none',
    _vm.isDisabled,
    {
      'default': _vm.isDefault,
      'primary': _vm.isPrimary,
      'primary-dark': _vm.isPrimaryDark,
      'danger': _vm.isDanger,
      'indigo': _vm.isIndigo,
      'link': _vm.isLink,
      'action': _vm.isAction && !_vm.disabled,
      'action-indigo': _vm.isActionIndigo && !_vm.disabled,
      'action-disabled': _vm.isAction && _vm.disabled,
      'py-8': _vm.isIcon && !_vm.isAction && !_vm.isLink,
      'py-11': !_vm.isIcon && !_vm.isAction && !_vm.isActionIndigo && !_vm.isLink && !_vm.loading,
      'py-6': _vm.loading
    }
  ],attrs:{"disabled":_vm.disabled}},_vm.$listeners),[(_vm.loading)?_c('el-loader',{attrs:{"loader-class":"w-22 h-22 mx-auto"}}):[(_vm.isIcon)?_c('div',{staticClass:"flex items-center justify-center pr-8"},[_vm._t("icon")],2):_vm._e(),_c('div',{staticClass:"flex-1"},[_vm._t("default")],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }